<template>
  <div id="app">
    <router-view/>
    <footer v-in-viewport>
      <a href="#" class="footer-logo">
        <img src="./assets/images/logo-secondary.svg">
      </a>
      <ul>
        <li><img src="./assets/images/email.svg">
        <a href="#" @click.prevent="contact = !contact">Contact Us</a>
        </li>
        <li><img src="./assets/images/linkedin.svg"><a href="https://www.linkedin.com/in/angie-bradbury-a803587/" target="_blank">Linkedin</a></li>
      </ul>
    </footer>
    <div class="contact-block" v-if="contact">
      <button class="contact-block__close" @click.prevent="contact = !contact">&times;</button>
      <div class="contact-block__box">
        <div class="contact-block__notification" v-if="notification">{{ notification }}</div>
        <form @submit.prevent="sendEmail">
          <label>Name</label>
          <input
            type="text"
            v-model="name"
            name="name"
            placeholder="Your Name"
          >
          <label>Email</label>
          <input
            type="email"
            v-model="email"
            name="email"
            placeholder="Your Email"
            >
          <label>Message</label>
          <textarea
            name="message"
            v-model="message"
            cols="30" rows="5"
            placeholder="Message">
          </textarea>
          <input type="submit" v-if="!notification" value="Send">
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import emailjs from 'emailjs-com';

export default {
  name: 'App',
  data: () => ({
    name: null,
    email: null,
    message: null,
    contact: false,
    notification: null,
  }),
  components: {
  },
  mounted() {
  },
  methods: {
    sendEmail(e) {
      const self = this;
      emailjs.sendForm('service_wsop9kj', 'template_kmvhahq', e.target,
        'user_nxIa2ngX5jErop7XlHbNR')
        .then((result) => {
          console.log(result);
          self.notification = 'Your message has been sent!';
        }, (error) => {
          console.log(error);
          self.notification = 'Oops your message failed, try again.';
        });
    },
  },
};
</script>

<style lang="scss">
body, html {
  padding: 0;
  margin: 0;
  line-height: 1.3;
  background: #191919;
}
*, *:before, *:after {
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  font-size: 16px;
  @include breakpoint(tablet) {
    font-size: 14px;
  }
}
.lazy {
  opacity: 0;
  transition: 0.6s ease-in-out opacity;
  &.lazy-loaded {
    opacity: 1;
  }
}
.below-viewport, .above-viewport {
  opacity: 0;
  transition: 0.6s ease-in-out opacity;
}
.in-viewport {
  opacity: 1;
  transition: 0.6s ease-in-out opacity;
}
.fade-in {
animation: fadeIn ease 0.6s;
-webkit-animation: fadeIn ease 0.6s;
-moz-animation: fadeIn ease 0.6s;
-o-animation: fadeIn ease 0.6s;
-ms-animation: fadeIn ease 0.6s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}
p {
  margin: 0 0 30px 0;
  width: 100%;
}
a {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  text-decoration: none;
}
img {
  max-width: 100%;
  display: block;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  padding-left: 0px;
  display: block;
  text-indent: 0;
}
ul li:before {
  content: "—";
  padding-right: 8px;
  display: inline-block;
}
h5 {
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 10px 0;
  letter-spacing: 0.5px;
  width: 100%;
}
button {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
}
footer {
  color: #FFFFFF;
  padding: 50px;
  position: relative;
  @include breakpoint(tablet) {
    padding: 20px 15px;
  }
  .footer-logo {
    position: absolute;
    left: 50px;
    top: 50px;
    width: 100px;
    border: 0;
    @include breakpoint(tablet) {
      left: 15px;
      bottom: 20px;
      top: auto;
      width: 80px;
    }
  }
  ul {
    text-align: right;
  }
  li {
    display: inline-block;
    margin-left: 5vw;
    img {
      margin-right: 10px;
      display: inline-block;
    }
    &:before {
      display: none;
    }
  }
}
.contact-block {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1002;
    background: rgba(#000000, 0.95);
    @include breakpoint(tablet) {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  .contact-block__close {
    position: fixed;
    top: 20px;
    right: 20px;
    color: #FFFFFF;
    font-size: 30px;
    line-height: 1;
  }
  .contact-block__box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    max-width: 100%;
    z-index: 1003;
    padding: 30px;
    border: 1px solid #FFFFFF;
    border-radius:  5px;
    @include breakpoint(tablet) {
      width: calc(100% - 40px);
      margin: 20vh 10px 20vh 10px;
      transform: translate(0%, 0%);
      position: relative;
      left: auto;
      top: auto;
    }
  }
  .contact-block__notification {
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
    padding-bottom: 10px;
    display: block;
  }
  input, textarea {
    width: 100%;
    background: transparent;
    appearance: none;
    border: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    border-bottom: 1px solid #FFFFFF;
    opacity: 1;
    color: #FFFFFF;
    outline: none;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 25px;
    max-width: 100%;
    display: block;
    resize: none;
  }
  textarea {
    border: 1px solid #FFFFFF;
  }
  input[type="submit"] {
    background: #FFFFFF;
    font-size: 12px;
    color: #000000;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
    border: 0;
  }
}
</style>
