import Vue from 'vue';
import inViewportDirective from 'vue-in-viewport-directive';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.directive('in-viewport', inViewportDirective);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
