<template>
  <div class="home">
    <div class="landing-block fade-in">
      <a class="logo" href="/">
        <img src="../assets/images/logo.svg">
      </a>
      <img src="../assets/images/landing-image.jpg">
      <span class="landing-block__arrow-down"><img src="../assets/images/arrow.svg"></span>
    </div>
    <div class="information-block">
      <div class="information-block__image">
        <img class="lazy" :data-src="img1">
      </div>
      <div class="information-block__text">
        <h5 v-in-viewport>What We Do</h5>
        <p v-in-viewport>How brands build their narrative and connect meaningfully with the
         right people is fundamental to build trust, engagement and ultimately
          commercial success.</p>
        <p v-in-viewport>Bradbury&Co consult on a broad range of business challenges including</p>
        <ul v-in-viewport>
          <li>Communications strategy</li>
          <li>Brand and marketing strategy</li>
          <li>Issues and Stakeholder management</li>
          <li>Consumer engagement</li>
          <li>Executive coaching and mentoring</li>
        </ul>
      </div>
    </div>
    <div class="about-block">
      <div class="about-block__text">
        <h5 v-in-viewport>About</h5>
        <p v-in-viewport>Angie Bradbury</p>
        <p v-in-viewport>A highly respected and sought-after marketing strategist
         with two decades working with leading brands in drinks &
          hospitality, retail & automotive, tourism & agriculture.</p>
        <p v-in-viewport>With a reputation as a straight forward, direct and pragmatic
         strategist who always pushes for the better answer or solution,
          Angie was the Founder and Managing Director of several leading
           marketing and communications agencies and is now an independent
            Consultant and Chair of Wine Victoria.</p>
        <p v-in-viewport>Angie was the 2019 Women of Inspiration for the Australian
         Women in Wine Awards, played a lead role in the development
          of the Diversity and Equality in Wine Charter and is a
           regular guest speaker, keynote presenter, workshop facilitator
            and agitator on issues right across the sector.</p>
        <a href="https://www.linkedin.com/in/angie-bradbury-a803587/" target="_blank">LinkedIn</a>
      </div>
      <div class="about-block__image">
        <img class="lazy" :data-src="img2">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LazyLoad from 'vanilla-lazyload';
import image1 from '../assets/images/melbourne1.jpg';
import image2 from '../assets/images/melbourne2.jpg';

export default {
  name: 'Home',
  data: () => ({
    name: null,
    email: null,
    message: null,
    img1: image1,
    img2: image2,
    contact: false,
  }),
  components: {
  },
  mounted() {
    this.myLazyLoad = new LazyLoad({
      elements_selector: '.lazy',
      class_loaded: 'lazy-loaded',
      load_delay: 500,
      threshold: 0,
    });
  },
  methods: {
  },
};
</script>

<style lang="scss">
.landing-block {
  height: 100vh;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .logo {
    width: 45%;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 0;
    transform: translate(-50%, -50%);
     @include breakpoint(tablet) {
      width: 220px;
     }
  }
  .landing-block__arrow-down {
    position: absolute;
    bottom: 50px;
    width: 30px;
    left: 50%;
    transform: translate(-50%, 0%);

  }
}
.information-block {
  color: #FFFFFF;
  padding: 25vh 15px 0 15px;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  @include breakpoint(tablet-landscape) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 15px 15px;
  }
  .information-block__text {
    grid-column: 7/12;
    @include breakpoint(tablet-landscape) {
      padding: 50px 0;
    }
    p {
      font-size: 34px;
       @include breakpoint(tablet) {
        font-size: 21px;
       }
    }
  }
  .information-block__image {
    grid-column: 2/6;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center center;
      @include breakpoint(tablet-landscape) {
        height: 60vh;
        object-fit: cover;
      }
    }
  }
}
.about-block {
  color: #FFFFFF;
  padding: 25vh 15px;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  @include breakpoint(tablet-landscape) {
    display: flex;
    padding: 50px 15px;
    flex-wrap: wrap;
  }
  .about-block__text {
    grid-column: 2/7;
    @include breakpoint(tablet-landscape) {
      padding-bottom: 50px;
    }
    p {
      font-size: 34px;
       @include breakpoint(tablet) {
        font-size: 21px;
       }
    }
  }
  .about-block__image {
    grid-column: 8/12;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center center;
      @include breakpoint(tablet-landscape) {
        height: 60vh;
        object-fit: cover;
      }
    }
  }
}
</style>
